.plot-box {
  background: rgba(255, 255, 255, 1);
  bottom: 2rem;
  left: 0;
  padding: 1rem;
  padding-bottom:0.5;
  position: absolute;
  width: 50.5rem;
}

.plot-storage {
  cursor: pointer;
  height: 15rem;
  padding-left: 0.5rem;
}

.plot-storage-row {
  display: flex;
  flex-direction: row;
}

.show-plot {
  background: rgba(255, 255, 255, 1);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: 1px solid gray;
  bottom: 7rem;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  position: absolute;
  width: 85px;
}

.contacts-plot-bottom {
  bottom: 1.5rem;
  height: 45%;
  left: 0;
  position: absolute;
  width: 68%;
} 

.contacts-plot-bottom.mini {
  left: auto;
  right: 0;
  width: 35%;
}

.contacts-plot-top {
  height: 45%;
  left: 0;
  position: absolute;
  top: 0;
  width: 68%;
}

.contacts-plot-top.mini {
  left: auto;
  right: 0;
  width: 35%;
}

.title-text {
  font-weight: bold;
  font-size: 0.95rem;
  text-align: center;
  margin-bottom: -1.1rem;
  margin-top: 10px;
  width: 100%;
}

.title-text-bottom {
  margin-top: 1.2rem;
}