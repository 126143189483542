.header {
	display: flex;
	height: 85px;
	padding: 0 10px 0 20px;
}

.header-logo {
	align-items: center;
	display: flex;
}

.header-menu {
	align-items: center;
	display: flex;
	width: 100%;
}

.header-menu li {
	list-style-type: none;
	margin: 0;
	padding: 0 25px;
	text-align: end;
}

.header-menu li:last-child {
	padding: 0 0 0 25px;
}

.header-menu li a.link {
	display: inline-block;
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 24px;
	font-weight: 600;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
  position: relative;
	text-decoration: none;
	z-index: 6010;
}

.header-menu li button:hover {
	color: gray;
	cursor: pointer;
}

.header-menu:hover li.navigation-tips:hover .submenu {
	display: block;
}

.header-menu-item {
	white-space: nowrap;
}

.link {
	background-color: transparent;
	border: none;
}

.link:focus {
	outline: none;
}

.map-description {
	float:right;
	padding-right: 5rem;
	color: gray;
	font-size: 1.05rem;
}

.map-description:hover {
	cursor: pointer;
}

.submenu {
	background-color: #fff;
	border: 1px solid #f1f1f1;
  display: none;
	padding-bottom: 1rem;
	padding-left: 1rem;
	padding-right: 0;
  position: absolute;
	right: 0;
	text-align: left;
	top: 80px;
	z-index: 6000;
}

.submenu h3 {
	font-size: 15px;
	margin: 0;
	padding: 15px 0 5px 0;
}

.submenu ul {
	padding-left: 0;
}

.submenu li {
	font-size: 13px;
	padding-bottom: 5px;
	text-align: left;
}

.navigation-tips {
	flex: 2;
}

.header-menu li.navigation-tips button {
	color: gray;
	font-size: 17px;
	white-space: nowrap;
}