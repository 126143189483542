*, *:before, *:after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

body {
	letter-spacing: 0.25px;
	line-height: 1.26;
	margin: 0;
	padding: 0;
	font-family: 'Playfair Display', serif;
}

h1 {
	margin: 10px;
	font-size: 1.2em;
}

h2 {
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 36px;
	letter-spacing: 0.25px;
	margin: 0 0 40px 0;
	text-transform: uppercase;
}

h3 {
	font-family: 'Open Sans Condensed', sans-serif;
	font-size: 24px;
	font-weight: 600;
	letter-spacing: 0.5px;
	margin: 0 0 20px 0;
	text-transform: uppercase;
}

li {
	font-size: 14px;
}

p {
	font-size: 14px;
	margin: 0 0 20px 0;
}

a {
	text-decoration:underline;
	color:#000;
}

a:hover { text-decoration: none }
a:visited { color:#000 }


.sections {
	margin: 40px 20px 10px 20px;
}