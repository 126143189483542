.arrow-navigation {
  height: 1.6em;
  padding: 4px;
}

.arrow-navigation:focus {
  outline: none;
}

.arrow-navigation:disabled {
  opacity: 0.5;
}

.maptype-option-item label {
  font-size: 14px;
}

.date-actions {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
}

.date-navigation-options {
  margin: 15px 0 0 0;
}