.legend {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 0;
  bottom: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.0);
  margin-bottom: 60px;
  padding: 5px;
  position: absolute;
  right: 50px;
	text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}

.legend-key {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}

.legend-item {
  cursor: pointer;
  padding: 0 5px;
}

.legend-item:hover {
  background-color: #dfdfdf;
}

.legend-item.selected .legend-value {
  font-weight: bolder;
  font-size: 14px;
}

.legend-value:focus {
  outline: none;
}
