#explorer {
  margin: 0 -20px;
}

#map {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 85px;
}

.loading {
	background: rgba(255, 255, 255, 0) !important;
	font-size: 1rem;
}

.actions {
  color: #333;
  display: flex;
  flex-direction: row;
}

.action {
  cursor: pointer;
}

.maptype-checkbox {
  padding: 2px !important;
  margin-left: 6px !important;
  margin-right: 5px !important;
}

.maptype-radio {
  padding: 2px !important;
  margin-left: 6px !important;
  margin-right: 5px !important;
}

.menu {
  background: #fff;
  border: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  margin: 20px;
  min-width: 300px;
}

.menu-content {
  padding: 10px;
}

.menu-header {
  align-items: center;
  background-color: #fff000;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  padding: 10px;
  text-transform: capitalize;
}

.menu-wrapper {
  position: absolute;
  top: 85px;
}

.share-types {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
}

.share-types .material-icons {
  color: #333;
}

.share-type {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding: 5px 8px 2px 8px;
}

.share-type:hover {
  background-color: #ddd;
}

.sources {
  display: flex;
  flex-direction: column;
}

.source {
  display: flex;
  align-items: center;
}

.source input, .source label {
  cursor: pointer;
}

/* REMOVE USING THE MAPBOX LIBRARY IN THE FUTURE */
.mapboxgl-ctrl-attrib-button {
  display: none;
}