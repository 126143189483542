.MuiTableCell-body.trending-down {
  color: green;
}

.MuiTableCell-body.trending-up {
  color: red;
}

.pagination.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0 40px 0;
}

.rank {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.subheader-search {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 0 0;
}

.subheader-search h6 {
  margin-right: 10px;
}

.trend-arrow.trending-down {
  color: green;
  margin-top: -5px;
  transform: rotate(90deg);
}

.trend-arrow.trending-up {
  color: red;
  margin-top: -5px;
  transform: rotate(-90deg);
}